const luesScreen = {
  manualInterpretation: false,
  parameters: [
    {
      name: 'vdrl',
      id: 'vdrl',
      displayName: 'VDRL',
      labReportName: 'Venereal disease research',
      validFrom: '2025/02/07 0:00',
      validThrough: null,
      stringValuesSupported: [
        'positive',
        'negative',
        'fault',
      ],
      outcomes: [{
        color: '#1f7359',
        description: 'vdrl-negative',
        name: 'negative',
        displayInterpretation: 'Negative'
      },
      {
        color: '#f52d0a',
        description: 'vdrl-positive',
        name: 'positive',
        displayInterpretation: 'Positive',
      },
      {
        color: '#000000',
        description: 'vdrl-fault',
        name: 'fault',
        displayInterpretation: 'Fault',
      }],
    },
  ],
};

export default luesScreen;