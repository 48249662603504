import albumin from './albumin';
import alt from './alt'
import basalCortisol from './basalCortisol';
import crp from './crp';
import ferritin from './ferritin';
import ferritinSante24 from './ferritinSante24';
import hba1c from './hba1c';
import hdl from './hdl';
import kreatinin from './kreatinin';
import ldl from './ldl';
import smallBloodCount from './smallBloodCount';
import totalCholesterin from './totalCholesterin';
import tsh from './tsh';
import tshSante24 from './tshSante24';
import uricAcid from './uricAcid'
import vitaminB12 from './vitaminB12';
import vitaminD3 from './vitaminD3';
import hiv from './hiv';
import luesScreen from './luesScreen';
import vdrl from './vdrl';
import antiHCV from './antiHCV';
import hbsAntigen from './hbsAntigen';
import antiHBC from './antiHBC';
import triglyceride from './triglyceride';
import hepatitisB from './hepatitisB';
import transferrinSaturation from './transferrinSaturation';
import fecalHemoglobin from './fecalHemoglobin'
import hpvGenotyping from './hpvGenotyping';
import gonorrhoea from './gonorrhoea';
import chlamydia from './chlamydia';


const numbericValues = {
  albumin,
  alt,
  basalCortisol,
  crp,
  ferritin,
  ferritinSante24,
  hba1c,
  hdl,
  kreatinin,
  ldl,
  smallBloodCount,
  totalCholesterin,
  tsh,
  tshSante24,
  uricAcid,
  vitaminB12,
  vitaminD3,
  triglyceride,
  transferrinSaturation,
};

const stringValues = {
  hiv,
  luesScreen,
  antiHCV,
  hbsAntigen,
  antiHBC,
  hepatitisB,
  fecalHemoglobin,
  hpvGenotyping,
  gonorrhoea,
  chlamydia,
  vdrl
};

export default {
  numbericValues,
  stringValues,
};